import {SAC_REQUEST, SAC_REQUEST_SUCCESS, SAC_REQUEST_FAIL, SET_SAC_SELECTED} from '../utils/ActionTypes';

const INITIAL_STATE = {
    data: [],
    isFetching: false,
    hasError: false,
    errorMessage: "",
    selected: {},
    total: 0,
    rowsPerPage: 14,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case SAC_REQUEST:
            return ({
                ...state,
                isFetching: true,
                hasError: false,
                data: []
            });
        case SAC_REQUEST_SUCCESS :
            return ({
                ...state,
                isFetching: false,
                hasError: false,
                data: action.payload.records,
                total: action.payload.total,
            });
        case SAC_REQUEST_FAIL:
            return ({
                ...state,
                isFetching: false,
                hasError: true,
            });
        case SET_SAC_SELECTED:
            return ({
                ...state,
                selected: action.selected
            });


        default :
            return state;
    }
}