import React from "react";
import {Redirect, Route} from "react-router-dom";

const AuthorizedRoute = ({component: Component, condition, redirectPath, ...rest}) => (

    <Route {...rest} render={props => {
        return condition
            ? <Component {...props} />
            : <Redirect to={{pathname: redirectPath || '/login/', state: {from: props.location}}}/>
    }}/>
);

export default AuthorizedRoute;