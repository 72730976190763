export const SET_AUTH = "SET_AUTH";
export const SET_USER = "SET_USER";
export const SET_SELECTED_TAB = "SET_SELECTED_TAB";
export const IS_OPEN_CLIENTS = "IS_OPEN_CLIENTS";

export const SET_SAC_SELECTED = "SET_SAC_SELECTED";
export const SAC_REQUEST = "SAC_REQUEST";
export const SAC_REQUEST_SUCCESS = "SAC_REQUEST_SUCCESS";
export const SAC_REQUEST_FAIL = "SAC_REQUEST_FAIL";

export const ALLEGATILEAD_GETFROMSAC = "ALLEGATILEAD_GETFROMSAC";
export const ALLEGATILEAD_REQUEST = "ALLEGATILEAD_REQUEST";
export const ALLEGATILEAD_REQUEST_SUCCESS = "ALLEGATILEAD_REQUEST_SUCCESS";
export const ALLEGATILEAD_REQUEST_FAIL = "ALLEGATILEAD_REQUEST_FAIL";

export const ALLEGATICOMM_GETFROMSAC = "ALLEGATICOMM_GETFROMSAC";
export const ALLEGATICOMM_REQUEST = "ALLEGATICOMM_REQUEST";
export const ALLEGATICOMM_REQUEST_SUCCESS = "ALLEGATICOMM_REQUEST_SUCCESS";
export const ALLEGATICOMM_REQUEST_FAIL = "ALLEGATICOMM_REQUEST_FAIL";


export const ALLEGATIOFFERTE_GETFROMSAC = "ALLEGATIOFFERTE_GETFROMSAC";
export const ALLEGATIOFFERTE_REQUEST = "ALLEGATIOFFERTE_REQUEST";
export const ALLEGATIOFFERTE_REQUEST_SUCCESS = "ALLEGATIOFFERTE_REQUEST_SUCCESS";
export const ALLEGATIOFFERTE_REQUEST_FAIL = "ALLEGATIOFFERTE_REQUEST_FAIL";

export const ALLEGATIORDINI_GETFROMSAC = "ALLEGATIORDINI_GETFROMSAC";
export const ALLEGATIORDINI_REQUEST = "ALLEGATIORDINI_REQUEST";
export const ALLEGATIORDINI_REQUEST_SUCCESS = "ALLEGATIORDINI_REQUEST_SUCCESS";
export const ALLEGATIORDINI_REQUEST_FAIL = "ALLEGATIORDINI_REQUEST_FAIL";

export const ALLEGATICOMMESSE_GETFROMSAC = "ALLEGATICOMMESSE_GETFROMSAC";
export const ALLEGATICOMMESSE_REQUEST = "ALLEGATICOMMESSE_REQUEST";
export const ALLEGATICOMMESSE_REQUEST_SUCCESS = "ALLEGATICOMMESSE_REQUEST_SUCCESS";
export const ALLEGATICOMMESSE_REQUEST_FAIL = "ALLEGATICOMMESSE_REQUEST_FAIL";

export const AMBITI_REQUEST = "AMBITI_REQUEST";
export const AMBITI_REQUEST_SUCCESS = "AMBITI_REQUEST_SUCCESS"
export const AMBITI_REQUEST_FAIL = "AMBITI_REQUEST_FAIL"

export const ATTIVITA_REQUEST = "ATTIVITA_REQUEST";
export const ATTIVITA_REQUEST_SUCCESS = "ATTIVITA_REQUEST_SUCCESS"
export const ATTIVITA_REQUEST_FAIL = "ATTIVITA_REQUEST_FAIL"

export const SPEC_REQUEST = "SPEC_REQUEST";
export const SPEC_REQUEST_SUCCESS = "SPEC_REQUEST_SUCCESS";
export const SPEC_REQUEST_FAIL = "SPEC_REQUEST_FAIL";

export const COMB_REQUEST = "COMB_REQUEST";
export const COMB_REQUEST_SUCCESS = "COMB_REQUEST_SUCCESS";
export const COMB_REQUEST_FAIL = "COMB_REQUEST_FAIL";

export const COSTI_REQUEST = "COSTI_REQUEST";
export const COSTI_REQUEST_SUCCESS = "COSTI_REQUEST_SUCCESS";
export const COSTI_REQUEST_FAIL = "COSTI_REQUEST_FAIL";

export const COMMESSE_REQUEST = "COMMESSE_REQUEST";
export const COMMESSE_REQUEST_SUCCESS = "COMMESSE_REQUEST_SUCCESS";
export const COMMESSE_REQUEST_FAIL = "COMMESSE_REQUEST_FAIL";

export const SET_CUSTOMER_SELECTED = "SET_CUSTOMER_SELECTED";
export const CUSTOMER_REQUEST = "CUSTOMER_REQUEST";
export const CUSTOMER_REQUEST_SUCCESS = "CUSTOMER_REQUEST_SUCCESS";
export const CUSTOMER_REQUEST_FAIL = "CUSTOMER_REQUEST_FAIL";


export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_REQUEST_SUCCESS = "LOGIN_REQUEST_SUCCESS";
export const LOGIN_REQUEST_FAIL = "LOGIN_REQUEST_FAIL";

export const IS_OPEN_USERS = "IS_OPEN_USERS";

export const IS_OPEN_LOG = "IS_OPEN_LOG";

export const USERS_REQUEST = "USERS_REQUEST";
export const USERS_REQUEST_SUCCESS = "USERS_REQUEST_SUCCESS";
export const USERS_REQUEST_FAIL = "USERS_REQUEST_FAIL";

export const LOGDOCS_REQUEST = "LOGDOCS_REQUEST";
export const LOGDOCS_REQUEST_SUCCESS = "LOGDOCS_REQUEST_SUCCESS";
export const LOGDOCS_REQUEST_FAIL = "LOGDOCS_REQUEST_FAIL";


export const SET_ORIGINAL_COLLAB = "SET_ORIGINAL_COLLAB"

export const IS_OPEN_AMBSPEC = "IS_OPEN_AMBSPEC"




export const PRODUCTS_REQUEST = "PRODUCTS_REQUEST";
export const PRODUCTS_REQUEST_SUCCESS = "PRODUCTS_REQUEST_SUCCESS";
export const PRODUCTS_REQUEST_FAIL = "PRODUCTS_REQUEST_FAIL";

export const PRODUCTSSAC_REQUEST = "PRODUCTSSAC_REQUEST";
export const PRODUCTSSAC_REQUEST_SUCCESS = "PRODUCTSSAC_REQUEST_SUCCESS";
export const PRODUCTSSAC_REQUEST_FAIL = "PRODUCTSSAC_REQUEST_FAIL";

export const IS_OPEN_PRODUCTS = "IS_OPEN_PRODUCTS";

export const ARTICOLICOMMESSE_REQUEST = "ARTICOLICOMMESSE_REQUEST";
export const ARTICOLICOMMESSE_REQUEST_SUCCESS = "ARTICOLICOMMESSE_REQUEST_SUCCESS";
export const ARTICOLICOMMESSE_REQUEST_FAILURE = "ARTICOLICOMMESSE_REQUEST_FAILURE";