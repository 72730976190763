import React from "react";
import ReactDOM from "react-dom"
import {Provider} from "react-redux"

import App from './App';
import Store from "./store/Store";

/**
 * Stili
 */
import "./style/index.css";

const routing = (
    <Provider store={Store}>
        <App/>
    </Provider>
);



ReactDOM.render(routing, document.getElementById('root'));