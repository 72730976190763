import {USERS_REQUEST, USERS_REQUEST_FAIL, USERS_REQUEST_SUCCESS} from "../utils/ActionTypes";

const INITIAL_STATE = {
    data: [],
    isFetching: false,
    hasError: false,
    errorMessage: "",
    selected: {},
    total: 0,
    rowsPerPage: 15,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case USERS_REQUEST:
            return ({
                ...state,
                isFetching: true,
                hasError: false,
                data: []
            });
        case USERS_REQUEST_SUCCESS :
            //console.log("total",action)
            return ({
                ...state,
                isFetching: false,
                hasError: false,
                data: action.payload.records,
                total: action.payload.total,
            });
        case USERS_REQUEST_FAIL:
            return ({
                ...state,
                isFetching: false,
                hasError: true,
                errorMessage: action.payload.response,
            });

        default :
            return state;

    }
}