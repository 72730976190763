import React, {lazy, Suspense} from "react";
import {HashRouter as Router, Route, Switch} from "react-router-dom";
import {connect} from "react-redux";
import Store from "./store/Store";
import AuthorizedRoute from "./utils/AuthorizedRoute";

import {createMuiTheme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";

const HomePage = lazy(() => import('./pages/HomePage'));
const LoginPage = lazy(() => import('./pages/LoginPage'));

/**
 * Tema dell'intera applicazione.
 *
 * @type {Theme}
 */
const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#2699fb",
            contrastText: "#FFF",
        },
        secondary: {
            main: "#ffffff",
            contrastText: "007feb",
        },
        darkBlue: "#007feb",
        gray: "#e2e2e2",
    },
    typography: {
        fontFamily: 'roboto, sans-serif',
        fontSize: 18,
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                fontSize: "16px",
            }
        }
    },
});

class App extends React.Component {
    isLoggedIn = () => {
        const storeState = Store.getState();
        //return true;
        return storeState.app.auth;
    };

    render() {
        return (
            <Router>
                <Switch>
                    <Suspense fallback={<div>Loading ...</div>}>
                        <ThemeProvider theme={theme}>
                         <AuthorizedRoute
                                exact path="/"
                                component={HomePage}
                                condition={this.isLoggedIn()}/>
                            <Route
                                exact path="/login"
                                component={LoginPage}/>    
                     
                        </ThemeProvider>
                    </Suspense>
                </Switch>
            </Router>
        );
    }
}

function mapStateToProps(state) {
    return {
        app: state.app,
    }
}

export default connect(mapStateToProps)(App);