import {SET_AUTH, SET_USER, SET_SELECTED_TAB, IS_OPEN_CLIENTS, IS_OPEN_USERS,IS_OPEN_PRODUCTS, IS_OPEN_LOG, IS_OPEN_AMBSPEC} from '../utils/ActionTypes';

/**
 * In caso serva per evitare di dover autenticarsi ogni volta si può usare
 * auth: true
 * user:{
 *     email@email.com,
 *     password: Pippo123!
 * }
 */
const INITIAL_STATE = {
    tabSelected: 0,
    auth: false,
    isOpenClients: false,
    user: {},
};

export default function (state = INITIAL_STATE, action) {
    
    switch (action.type) {
        case SET_AUTH:
            return ({
                ...state,
                auth: action.isAuth
            });

        case SET_USER:
            return ({
                ...state,
                user: action.user
            });

        case SET_SELECTED_TAB:
            return ({
                ...state,
                tabSelected: action.tabSelected,
                isOpenClients: false,
            });

        case IS_OPEN_CLIENTS:
            return ({
                ...state,
                isOpenClients: action.isOpenClients,
            });

        case IS_OPEN_USERS:
            return ({
                ...state,
                isOpenUsers: action.isOpenUsers,
            });

            case IS_OPEN_PRODUCTS:
                return ({
                    ...state,
                    isOpenProducts: action.isOpenProducts,
                });
        case IS_OPEN_LOG:
            return ({
                ...state,
                isOpenDocsLog: action.isOpenDocsLog,
            });
        case IS_OPEN_AMBSPEC:
            return ({
                ...state,
                isOpenAmbSpec: action.isOpenAmbSpec,
            });
        default :
            return state;

    }
}