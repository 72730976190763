import {COSTI_REQUEST, COSTI_REQUEST_SUCCESS,COSTI_REQUEST_FAIL} from '../utils/ActionTypes';

const INITIAL_STATE = {
    data: [],
    isFetching: false,
    hasError: false,
    errorMessage: "",
    selected: {},
    total: 0,
    rowsPerPage: 5,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case COSTI_REQUEST:
            return ({
                ...state,
                isFetching: true,
                hasError: false,
                data: []
            });
        case COSTI_REQUEST_SUCCESS :
            return ({
                ...state,
                isFetching: false,
                hasError: false,
                data: action.payload.records,
                total: action.payload.total,
            });
        case COSTI_REQUEST_FAIL:
            return ({
                ...state,
                isFetching: false,
                hasError: true,
            });
     


        default :
            return state;
    }
}