import {ALLEGATIORDINI_GETFROMSAC,ALLEGATIORDINI_REQUEST,ALLEGATIORDINI_REQUEST_SUCCESS,ALLEGATIORDINI_REQUEST_FAIL} from '../utils/ActionTypes';

const INITIAL_STATE = {
data: [],
isFetching: false,
hasError: false,
errorMessage: "",
selected: {},
total: 0,
rowsPerPage: 5,
};

export default function (state = INITIAL_STATE, action) {
   // console.log("redices",action)
switch (action.type) {
    case ALLEGATIORDINI_REQUEST:
        return ({
            ...state,
            isFetching: true,
            hasError: false,
            data: []
        });
case ALLEGATIORDINI_REQUEST_SUCCESS :
   // console.log("reqest")
        return ({
            ...state,
            isFetching: false,
            hasError: false,
            data: action.payload.records,
            total: action.payload.total,
        });
    case ALLEGATIORDINI_REQUEST_FAIL:
        return ({
            ...state,
            isFetching: false,
            hasError: true,
        });
        case ALLEGATIORDINI_GETFROMSAC :
                return ({
                    ...state,
                    isFetching: false,
                    hasError: false,
                    data: action.payload.records,
                    total: action.payload.total,
                });


    default :
        return state;
}
}