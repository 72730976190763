import {SET_ORIGINAL_COLLAB} from '../utils/ActionTypes';

const INITIAL_STATE = {
    data: [],
    isFetching: false,
    hasError: false,
    errorMessage: "",
    selected: {},
    total: 0,
    rowsPerPage: 5,
    ruolo:""
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case SET_ORIGINAL_COLLAB:
            return ({
                ...state,
                data: action.data
            });
     


        default :
            return state;
    }
}