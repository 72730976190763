import {combineReducers} from 'redux';
import AppReducer from './AppReducer';
import SacReducer from "./SacReducer";
import CustomerReducer from "./CustomerReducer";
import AllegatiLeadReducer from "./AllegatiLeadReducer";
import AllegatiCommReducer from "./AllegatiCommReducer";
import AllegatiOfferteReducer from "./AllegatiOfferteReducer";
import AllegatiOrdiniReducer from "./AllegatiOrdiniReducer";
import AllegatiCommesseReducer from "./AllegatiCommesseReducer";
import AmbitiReducer from "./AmbitiReducer"
import AttivitaReducer from "./AttivitaReducer"
import SpecificheReducer from "./SpecificheReducer";
import CombinazioniReducer from "./CombinazioniReducer";
import CostiReducer from './CostiReducer';
import CommesseReducer from './CommesseReducer';
import LoginReducer from './LoginReducer';
import UsersReducer from './UsersReducer';
import CollabReducer from './CollabReducer';
import LogDocsReducer from './LogDocsReducer';
import ProductReducer from './ProductReducer';
import ProductSacReducer from './ProductSacReducer';



export default combineReducers({
    app: AppReducer,
    sac: SacReducer,
    customer: CustomerReducer,
    product:ProductReducer,
    productsac:ProductSacReducer,
    allegatilead: AllegatiLeadReducer,
    allegaticomm: AllegatiCommReducer,
    allegatiofferte: AllegatiOfferteReducer,
    allegatiordini: AllegatiOrdiniReducer,
    allegaticommesse: AllegatiCommesseReducer,
    ambiti: AmbitiReducer,
    attivita: AttivitaReducer,
    specifiche: SpecificheReducer,
    combinazioni: CombinazioniReducer,
    costi: CostiReducer,
    commesse: CommesseReducer,
    login: LoginReducer,
    users: UsersReducer,
    collaboratori: CollabReducer,
    docs: LogDocsReducer

})