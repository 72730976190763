import {LOGIN_REQUEST, LOGIN_REQUEST_SUCCESS,LOGIN_REQUEST_FAIL} from '../utils/ActionTypes';

const INITIAL_STATE = {
    data: [],
    isFetching: false,
    hasError: false,
    errorMessage: "",
    selected: {},
    total: 0,
    rowsPerPage: 5,
    ruolo:""
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {

        case LOGIN_REQUEST:
            return ({
                ...state,
                isFetching: true,
                hasError: false,
                data: []
            });
        case LOGIN_REQUEST_SUCCESS :
            return ({
                ...state,
                isFetching: false,
                hasError: false,
                data: action.payload.records,
                total: action.payload.total,
            });
        case LOGIN_REQUEST_FAIL:
            return ({
                ...state,
                isFetching: false,
                hasError: true,
            });
     


        default :
            return state;
    }
}